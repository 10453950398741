import React, { useState } from "react";
import { login } from "../../modules/User/actions";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";

import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CImg,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { Redirect } from "react-router-dom";

const Login = ({ login, isAuthenticated }) => {
  const [formData, setformData] = useState({
    username: "",
    password: "",
  });

  const { username, password } = formData;

  const onChange = (e) => {
    setformData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    login(username, password);
  };

  const role = useSelector((state) => state.auth.user.role);

  if (isAuthenticated && (role === "admin" || role === "partner")) {
    return <Redirect to="/dashboard" />;
  } else if (isAuthenticated && role === "branch") {
    return <Redirect to="/branchDashboard" />;
  } else if (isAuthenticated && role === "deliveryexecutive") {
    return <Redirect to="/assignOrders" />;
  } else if (isAuthenticated && role === "storeexecutive") {
    return <Redirect to="/createOrders" />;
  } else if (isAuthenticated && role === "orderexecutive") {
    return <Redirect to="/currentOrders" />;
  } else {
    return (
      <div
        className="c-app c-default-layout flex-row align-items-center"
        style={{
          background: `url('./images/bg1.jpg')`,
          backgroundRepeat: `repeat`,
          objectFit: `contain`,
        }}
      >
        <CContainer>
          <CRow className="justify-content-center">
            <CCol md="8">
              <CCardGroup
                style={{ boxShadow: "0px 0px 10px", borderRadius: "10px" }}
              >
                <CCard
                  className="text-white hand py-5 d-md-down-none d-flex justify-content-center align-items-center"
                  style={{ width: "44%" }}
                >
                  <CImg
                    src="/images/logo/icon.jpeg"
                    width="140"
                    height="140"
                    style={{ borderRadius: "5px" }}
                  />
                </CCard>

                <CCard className="p-4">
                  <CCardBody>
                    <CForm method="post" onSubmit={onSubmit}>
                      <h1>Login</h1>
                      <p className="text-muted">
                        Welcome Back! Login to you account.
                      </p>
                      <CInputGroup className="mb-3">
                        <CInputGroupPrepend>
                          <CInputGroupText>
                            <CIcon name="cil-user" />
                          </CInputGroupText>
                        </CInputGroupPrepend>
                        <CInput
                          // type="email"
                          placeholder="Username"
                          name="username"
                          autoComplete="username"
                          value={username}
                          onChange={onChange}
                          required
                        />
                      </CInputGroup>
                      <CInputGroup className="mb-4">
                        <CInputGroupPrepend>
                          <CInputGroupText>
                            <CIcon name="cil-lock-locked" />
                          </CInputGroupText>
                        </CInputGroupPrepend>
                        <CInput
                          type="password"
                          placeholder="Password"
                          name="password"
                          autoComplete="current-password"
                          value={password}
                          onChange={onChange}
                          required
                        />
                      </CInputGroup>
                      <CRow>
                        <CCol xs="6">
                          <CButton
                            // color="dark"
                            style={{
                              backgroundColor: "#363b4a",
                              color: "white",
                            }}
                            block
                            className="px-4"
                            active
                            tabIndex={-1}
                            type="submit"
                          >
                            Login
                          </CButton>
                        </CCol>
                        <CCol xs="6" className="text-right">
                          {/* <CButton color="link" className="px-0">
                            Forgot password?
                          </CButton> */}
                        </CCol>
                      </CRow>
                    </CForm>
                  </CCardBody>
                </CCard>
              </CCardGroup>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    );
  }
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { login })(Login);
