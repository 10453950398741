// import { number } from "prop-types";
import {
  LOADED,
  LOADING,
  GET_CATEGORY,
  APP_STATUS,
  UPDATE_LIMIT,
} from "./constants";

const initialState = {
  loading: false,
  categories: [],
  appStatus: false,
  OHELimit: 0,
  DBLimit: 0,
  minDeliveryCharge: 0,
  minDeliveryDistance: 0,
};

export default function settings(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case LOADED:
      return {
        ...state,
        loading: false,
      };
    case GET_CATEGORY:
      return {
        ...state,
        categories: payload,
      };
    case APP_STATUS:
      return {
        ...state,
        appStatus: payload.appStatus,
        OHELimit: payload.OHELimit,
        DBLimit: payload.DBLimit,
        minDeliveryCharge: payload.minDeliveryCharge,
        minDeliveryDistance: payload.minDeliveryDistance,
      };
    case UPDATE_LIMIT:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}
