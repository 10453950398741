import { combineReducers } from "redux";
import sidebar from "./modules/Sidebar/reducer";
import auth from "./modules/User/reducer";
import alert from "./modules/Alert/reducer";
import branch from "./modules/Branch/reducer";
import admin from "./modules/Admin/reducer";
import settings from "./modules/Settings/reducer";
import executives from "./modules/Executives/reducer";
import cart from "./modules/cart/reducer";

export default combineReducers({
  sidebar,
  auth,
  alert,
  settings,
  branch,
  admin,
  executives,
  cart,
});
