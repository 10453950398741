import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { CAlert } from "@coreui/react";

const Alert = ({ alerts }) =>
  alerts !== null &&
  alerts.length > 0 &&
  alerts.map((alert) => (
    <CAlert
      key={alert.id}
      className={`alert alert-${alert.alertType} w-25 `}
      style={{
        position: "fixed",
        right: 10,
        top: 10,
        zIndex: 100000,
        transition: "ease-in 1",
        fontWeight: "500",
        border: `1px solid ${getBorderColor(alert.alertType)}`,
      }}
      closeButton
    >
      {alert.msg}
    </CAlert>
  ));

const getBorderColor = (color) => {
  switch (color) {
    case "danger":
      return "darkred";
    case "info":
      return "darkblue";
    case "success":
      return "green";
  }
};

Alert.propTypes = {
  alerts: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  alerts: state.alert,
});

export default connect(mapStateToProps)(Alert);
