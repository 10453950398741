import { GET_BRANCHES } from "./constants";

const initialState = {
  count: 0,
  pagination: {},
  branches: [],
};

export default function branch(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_BRANCHES:
      return {
        ...state,
        count: payload.count,
        pagination: payload.pagination,
        branches: payload.data,
      };

    default:
      return state;
  }
}
