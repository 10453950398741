import {
  GET_ACTIVE_DB,
  LOADING,
  LOADED,
  GET_DELIVERY_COUNT,
} from "./constants";

const initialState = {
  db: [],
  loading: false,
  pagination: {},
  count: [],
};

export default function store(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case LOADED:
      return {
        ...state,
        loading: false,
      };

    case GET_ACTIVE_DB:
      return {
        ...state,
        db: payload,
        loading: false,
      };
    case GET_DELIVERY_COUNT:
      return {
        ...state,
        count: payload,
      };
    default:
      return state;
  }
}
